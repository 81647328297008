/* external */
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useState } from 'react';
import { uniqBy } from 'lodash';

/* Material UI */
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Hidden,
  Tab,
  Tabs,
  useMediaQuery,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

/* internal */
import Header from './Header';
import OpportunityAppointments from './OpportunityAppointments';
import OpportunityAttachments from './OpportunityAttachments';
import OpportunityCommunications from './OpportunityCommunications';
import OpportunityNotes from './OpportunityNotes';
import OpportunityPitches from './OpportunityPitches';
import OpportunitySummaryCards from './OpportunitySummaryCards';
import OpportunityTasks from './OpportunityTasks';
import CodeTablesContext from 'components/MaterialUI/CodeTablesContext';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import ErrorPage from 'components/MaterialUI/ErrorPage';
import Loading from 'components/MaterialUI/Loading';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { H2H_DEALER_ID, Role } from 'constants.js';
import TradeInsCard from 'modules/appraisals/components/TradeInsCard';
import { AppraisalType } from 'modules/appraisals/const';
import CreditAppsCard from 'modules/credit_apps/components/CreditAppsCard';
import EditCustomerDetails from 'modules/customers/components/CustomerDetails/EditCustomerDetails';
import PitchFormContainer from 'modules/pitches/components/PitchFormContainer';
import CustomerAvatar from 'components/CustomerAvatar';

const OPPORTUNITY_DETAILS = gql`
  fragment Opportunity on Opportunity {
    _id
    appraisals {
      id
      ...TradeInsCardAppraisal
    }
    bdc_reps {
      username
      display_name
    }
    customer_reps {
      username
      display_name
    }
    crm_lead_ids
    customer_name
    status
    status_display
    sub_status
    dealer_id
    lost_reason
    last_status_change
    preferences
    deal_type
    stock_type
    carryover_date
    sent_to_fi_date
    sales_manager_usernames
    sales_rep_usernames
    finance_manager_usernames
    organization_id
    dms_deal {
      make_name
      model_name
      year
      stock_number
      deal_number
      deal_type
    }
    sales_managers {
      username
      display_name
    }
    sales_reps {
      username
      display_name
    }
    finance_managers {
      username
      display_name
    }
    notes {
      body
      created
      visibility_display
      visibility
      dealer_id
      created_by {
        display_name
        role
      }
      dealer {
        dealer_name
      }
    }
    customer {
      _id
      address
      first_name
      last_name
      fullname
      company_name
      city
      province
      postal_code
      records
      identities {
        identity_type
        identity_value
      }
      credit_apps {
        id
        dateCreated
        dealerId
        ...CreditAppsCardCreditApp
      }
      vehicles {
        make
        model
        trim
        year
        vin
      }
      ...EditCustomerDetailsFragment
      ...CustomerAvatarCustomer
    }
    primary_new_pitch_id
    pitches {
      id
      ...OpportunityPitchesPitch
    }
    ...HeaderOpportunity
    ...OpportunityPitchesOpportunity
    ...PitchFormContainerOpportunity
    ...OpportunityAppointments
    ...OpportunityMarketing
  }
  ${EditCustomerDetails.fragments.customer}
  ${Header.fragments.opportunity}
  ${OpportunityAppointments.fragments.opportunity}
  ${OpportunityPitches.fragments.pitch}
  ${OpportunityPitches.fragments.opportunity}
  ${PitchFormContainer.fragments.opportunity}
  ${TradeInsCard.fragments.appraisal}
  ${OpportunitySummaryCards.fragments.opportunity}
  ${CreditAppsCard.fragments.creditApp}
  ${CustomerAvatar.fragments.customer}
`;

const OPPORTUNITY_QUERY = gql`
  query Opportunity($_id: ID!) {
    opportunity(_id: $_id) {
      ...Opportunity
    }
  }
  ${OPPORTUNITY_DETAILS}
`;

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
  },
  tabPanelGridContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    margin: 0,
    width: '100%',
  },
  tabLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
}));

const TabPanel = ({ value, index, children }) =>
  value === index && <Box>{children}</Box>;

const BoxOrDialog = ({ children, open }) =>
  useMediaQuery(theme => theme.breakpoints.down('sm')) ? (
    <Dialog fullScreen open={open}>
      <DialogContent style={{ padding: 0 }}>{children}</DialogContent>
    </Dialog>
  ) : (
    <Box
      width="100%"
      paddingLeft={1.5}
      paddingRight={1.5}
      paddingTop={1.5}
      margin={0}
    >
      {children}
    </Box>
  );

const OpportunityDetails = ({ opportunityId }) => {
  const { currentUser } = useUserContext();
  const [pitchEditState, setPitchEditState] = useState({
    showPitchForm: false,
    editPitchId: null,
    pitchStep: 0,
  });

  const { dealerId, setDealerId } = useDealerContext();
  const [tabIndex, setTabIndex] = useState(0);
  const classes = useStyles();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { data, error, loading, refetch } = useQuery(OPPORTUNITY_QUERY, {
    variables: {
      _id: opportunityId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  const onEditPitch = editPitchId =>
    setPitchEditState({ showPitchForm: true, editPitchId, pitchStep: 0 });

  const { opportunity } = data ?? {};
  const { customer } = opportunity ?? {};
  const { vehicles } = customer ?? {};

  const isSalesPerson = Role.ALL_SALES_REPS.includes(currentUser.role);

  if (loading && !data) {
    return <Loading />;
  }

  // We need to allow service staff to view opp details while still "hiding" the credit apps.
  // We won't display the error page in this case.
  const creditAppErrors = error?.graphQLErrors?.filter(
    x => x.path.includes('credit_apps') && x.message.includes('403'),
  );

  if (!creditAppErrors && error)
    return <ErrorPage action="loading opportunity" error={error} />;

  // If the request returns null we cant load the opportunity.
  if (!opportunity) {
    return <ErrorPage action="loading opportunity" error={error} />;
  }

  // Sales Persons can only access opps they are assigned to
  // Internet Sales Persons should be able to read opps with BDC rep(s) assigned
  if (
    isSalesPerson &&
    ![
      ...opportunity?.sales_rep_usernames,
      ...opportunity?.sales_manager_usernames,
    ].includes(currentUser.username) &&
    currentUser.role !== Role.INTERNET_SALES_REP
  )
    return <ErrorPage action="loading opportunity" error={error} />;

  if (dealerId && opportunity.dealer_id !== dealerId) {
    setDealerId(opportunity.dealer_id);
  }

  const thirtyDaysAgo = moment()
    .subtract(30, 'days')
    .startOf('day')
    .toISOString();

  return (
    <>
      <LoadingBackdrop open={loading}>Re-fetching Opportunity</LoadingBackdrop>
      <Header opportunity={opportunity} refetch={refetch} />
      <OpportunitySummaryCards
        customer={customer}
        opportunity={opportunity}
        refetchOpportunity={refetch}
      />
      <Hidden xsUp={pitchEditState.showPitchForm}>
        <Tabs
          TabIndicatorProps={{ style: { background: '#ff5a00' } }}
          style={{ margin: '12px' }}
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={tabIndex}
          onChange={(event, tabIndex) => setTabIndex(tabIndex)}
        >
          <Tab className={classes.tabLabel} label="Details" />
          <Tab className={classes.tabLabel} label="Notes" />
          <Tab className={classes.tabLabel} label="Attachments" />
          <Tab className={classes.tabLabel} label="Tasks" />
          <Tab className={classes.tabLabel} label="Credit Apps" />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <Grid
            container
            spacing={3}
            direction={desktop ? 'row' : 'column'}
            justifyContent={desktop ? 'space-between' : 'center'}
            alignItems={'stretch'}
            className={classes.tabPanelGridContainer}
          >
            <Grid item xs={12}>
              <OpportunityAppointments
                appointments={data.opportunity.appointments}
                customer={data.opportunity.customer}
                opportunity={data.opportunity}
                refetch={refetch}
              />
            </Grid>
            {/* Credit app not used yet */}
            {/* <Grid item xs={desktop ? 6 : 12}>
              <CreditApplication application={'TODO'} />
            </Grid> */}
            <Grid item xs={12}>
              <OpportunityPitches
                customer={customer}
                onAddPitch={() =>
                  setPitchEditState({
                    showPitchForm: true,
                    editPitchId: null,
                    pitchStep: 0,
                  })
                }
                opportunity={opportunity}
                onEditPitch={onEditPitch}
                pitches={data?.opportunity.pitches}
                refetchOpportunity={refetch}
              />
            </Grid>
            <Grid item xs={desktop ? 6 : 12}>
              <TradeInsCard
                appraisals={opportunity.appraisals}
                allowEdit
                allowNew
                defaultAppraisal={{
                  dealerId: opportunity.dealer_id,
                  opportunityId: opportunity._id,
                  customerId: opportunity.customer_id,
                  appraisalType: AppraisalType.TRADE_IN,
                  firstName: customer.first_name,
                  lastName: customer.last_name,
                }}
                emptyMessage="There are no trades for this opportunity."
                refetch={refetch}
              />
            </Grid>
            <Grid item xs={desktop ? 6 : 12}>
              <OpportunityCommunications communications={'TODO'} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <OpportunityNotes opportunity={data.opportunity} />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <OpportunityAttachments opportunity={data.opportunity} />
        </TabPanel>
        <TabPanel value={tabIndex} index={3}>
          <OpportunityTasks
            opportunity={data.opportunity}
            dealerId={data.opportunity.dealer_id}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={4}>
          <CreditAppsCard
            creditApps={data.opportunity.customer.credit_apps
              .filter(creditApp => creditApp)
              .filter(
                creditApp =>
                  [dealerId, H2H_DEALER_ID].includes(creditApp.dealerId) &&
                  moment(`${creditApp.dateCreated}Z`).isAfter(thirtyDaysAgo),
              )}
            errors={uniqBy(creditAppErrors, 'message')}
            emptyMessage="There are no credit applications for this opportunity"
            refetch={refetch}
            title="Credit Applications (last 30 days)"
          />
        </TabPanel>
      </Hidden>
      {pitchEditState.showPitchForm && (
        <CodeTablesContext>
          <BoxOrDialog open={pitchEditState.showPitchForm}>
            <PitchFormContainer
              opportunity={opportunity}
              id={pitchEditState.editPitchId}
              onCancel={() =>
                setPitchEditState({
                  showPitchForm: false,
                  editPitchId: null,
                  pitchStep: 0,
                })
              }
              onEditPitch={editPitchId => {
                setPitchEditState({
                  showPitchForm: true,
                  editPitchId,
                  pitchStep: 0,
                });
              }}
              pitchEditState={pitchEditState}
              setPitchEditState={setPitchEditState}
              refetch={refetch}
              customerVehicles={vehicles}
            />
          </BoxOrDialog>
        </CodeTablesContext>
      )}
    </>
  );
};

export default OpportunityDetails;
