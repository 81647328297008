import gql from 'graphql-tag';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Box, Grid, useMediaQuery } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';

import {
  KeyboardDatePickerControl,
  SelectControl,
  TextFieldControl,
} from 'components/MaterialUI';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { formatPrice } from 'utils';
import { PriceModifierTypes } from '../constants';

import {
  cashAddonTotal,
  cashAdjustments,
  cashDiscountTotal,
  cashRebateTotal,
  financeAddonTotal,
  financeAdjustments,
  financeDiscountTotal,
  financeRebateTotal,
} from '../../../utils';
import { usePermissionsContext } from '../../contexts/PermissionsContext';
import Controller from '../../contexts/PermissionsController';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiInputBase-root': {
      padding: 0,
      '& .MuiButtonBase-root': {
        padding: 0,
        paddingLeft: 5,
      },
      '& .MuiInputBase-input': {
        paddingLeft: 0,
      },
    },
    '& .MuiTableCell-root': {
      [theme.breakpoints.up('sm')]: {
        borderBottom: 0,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
        padding: '5px 0',
      },
    },
  },
  priceLine: {
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  mobileLayout: {
    flexDirection: 'row',
  },
  mobileInputs: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
  responsiveCheckbox: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: 0,
    textAlign: 'center',
  },
  tableHeader: {
    fontWeight: 'bold',
  },
  discountApplies: {
    display: 'flex',

    [theme.breakpoints.up('sm')]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
      maxWidth: '400px',
    },
    [theme.breakpoints.up('lg')]: {
      flexWrap: 'noWrap',
      columnGap: '15px',
    },
  },
}));

const UsedDetailedPricing = ({
  append,
  fields,
  remove,
  vehicle,
  fieldsRebates,
  appendRebates,
  removeRebates,
}) => {
  const { control, setValue } = useFormContext();

  const classes = useStyles();

  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const mobileSm = useMediaQuery('(max-width:450px)');
  const mdDevices = useMediaQuery(theme => theme.breakpoints.up('md'));
  const lgDevices = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const { me } = useUserContext();
  const { update_detailed_pricing: canUpdateDetailPrice, update } =
    usePermissionsContext();

  const dollarStart = {
    startAdornment: <InputAdornment position="start">$</InputAdornment>,
  };

  const tz =
    me?.goUserProfile?.settings?.timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  const regular = useWatch({
    control,
    name: 'regular_price',
    defaultValue: vehicle.regular_price,
  });

  const detailedPricingBase = 'regular';

  const [addNewLine, setAddNewLine] = useState();
  const [addNewRebate, setAddNewRebate] = useState();

  const useRegular = detailedPricingBase;

  const detailedPricing = useWatch({
    control,
    name: 'detailed_pricing',
  });

  const rebates = detailedPricing.filter(
    x => x.price_modifier_type_name === PriceModifierTypes.REBATE,
  );

  const detailedPricingNoRebates = detailedPricing.filter(
    x => x.price_modifier_type_name !== PriceModifierTypes.REBATE,
  );

  let specialPriceExpires = null;

  const specialPriceExpired =
    detailedPricing &&
    detailedPricing[detailedPricing?.length - 1]?.expiry &&
    moment
      .tz(tz)
      .isAfter(
        moment.tz(detailedPricing[detailedPricing?.length - 1]?.expiry, tz),
      );

  if (detailedPricing?.length > 0 && !specialPriceExpired) {
    const expiryDates = detailedPricing?.map(({ expiry }) => expiry);
    const sortedDates = expiryDates.sort();

    specialPriceExpires = sortedDates[sortedDates.length - 1];
  }
  if (specialPriceExpires) {
    setValue('special_price_expires', moment(specialPriceExpires));
  }

  const cashPrice = useRegular
    ? (regular ? parseFloat(regular) : 0) +
      cashAdjustments(
        fields.length > 0 || fieldsRebates?.length > 0 ? detailedPricing : [],
        me?.goUserProfile?.settings?.timezone,
      )
    : null;

  const financePrice = useRegular
    ? (regular ? parseFloat(regular) : 0) +
      financeAdjustments(
        fields.length > 0 || fieldsRebates?.length > 0 ? detailedPricing : [],
        me?.goUserProfile?.settings?.timezone,
      )
    : null;

  // react-hook-form does not set detailed_pricing to [] when removing the
  // last item.  The docs mention that "watch" behaves this way, but not
  // that the data behaves the same way!
  // Note that this is current as of version 6.5.2; maybe it's fixed in future
  // versions (we have not upgraded since the newer versions seem to submit
  // the entire defaultValues provided, rather than only those that are registered
  // or used in Controller)
  // " When all inputs get removed from the Field Array, watch will return
  // defaultValues. You can use fields.length to avoid this behaviour.
  // eg fields.length ? watch('fieldArray', fields) : []"
  //
  // See:  https://react-hook-form.com/api#useFieldArray

  useEffect(() => {
    if (fields?.length === 0) {
      setValue('special_price_expires', null);
      setValue('special_price', '');
      setValue('is_special_price_enabled', false);
    }
  }, [fields, setValue]);

  useEffect(() => {
    if (fieldsRebates?.length === 0) {
      setValue('special_price_expires', null);
      setValue('special_price', '');
      setValue('is_special_price_enabled', false);
    }
  }, [fieldsRebates, setValue]);

  useEffect(() => {
    if (fields.length !== 0 || fieldsRebates !== 0) {
      if (
        detailedPricingBase === 'regular' &&
        regular &&
        parseFloat(regular) !== 0
      ) {
        setValue(
          'special_price',
          parseFloat(regular) +
            cashAdjustments(
              fields?.length > 0 || fieldsRebates?.length > 0
                ? detailedPricing
                : [],
              me?.goUserProfile?.settings?.timezone,
            ),
        );
        setValue('is_detailed_pricing', true);
        setValue('is_special_price_enabled', true);
      } else setValue('is_detailed_pricing', false);
    } else setValue('is_detailed_pricing', false);
  }, [
    fields,
    fieldsRebates,
    detailedPricing,
    detailedPricingBase,
    me,
    regular,
    setValue,
  ]);

  return (
    <Container>
      <Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '20px',
            columnGap: '20px',
          }}
        >
          <Typography style={{ fontWeight: 'bold' }}>Rebates</Typography>
          <Fab
            disabled={!canUpdateDetailPrice}
            size="small"
            onClick={() => {
              setAddNewRebate(true);
              appendRebates({
                // These do not appear to be setting the default values, other
                // than price_modifier_type_name.
                // Note that the default expiry in KeyboardDatePicker is
                // today's date, BUT ALSO TODAY'S TIME.
                cash: false,
                expiry: null,
                finance: false,
                lease: false,
                price_description: '',
                price_modifier_type_name: PriceModifierTypes.REBATE,
                value: 0,
                show_on_website: false,
              });
            }}
            style={{
              display: 'flex',
              backgroundColor: canUpdateDetailPrice ? '#5bc236' : 'grey',
              color: 'white',
            }}
          >
            <AddIcon />
          </Fab>
        </Box>
        {(rebates?.length > 0 || addNewRebate === true) && (
          <>
            {fieldsRebates
              ?.filter(
                x => x.price_modifier_type_name === PriceModifierTypes.REBATE,
              )
              .map((item, index) => {
                return (
                  <Grid
                    container
                    key={item.key}
                    justifyContent={mdDevices ? 'space-between' : 'flex-start'}
                    className={classes.priceLine}
                    spacing={lgDevices ? 1 : mobile ? 3 : 2}
                  >
                    {!mobile && (
                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingBottom: '10px',
                          fontSize: '14px',
                          textDecorationLine: 'underline',
                          fontWeight: 'bold',
                        }}
                      >
                        Detailed Price #{index + 1}
                      </Grid>
                    )}
                    {/* Include id if the item already exists */}
                    <TextFieldControl
                      control={control}
                      style={{ display: 'none' }}
                      name={`rebates.${index}.id`}
                      defaultValue={item.id}
                    />
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      md={2}
                      lg={1}
                      style={{ marginRight: lgDevices ? '15px' : 0 }}
                    >
                      <FormControl
                        style={{
                          width: lgDevices ? '120%' : '100%',
                        }}
                      >
                        <SelectControl
                          control={control}
                          defaultValue={item.price_modifier_type_name}
                          name={`rebates.${index}.price_modifier_type_name`}
                          className={classes.mobileInputs}
                          disabled={true}
                          options={[
                            {
                              name: 'Rebate',
                              value: PriceModifierTypes.REBATE,
                            },
                          ]}
                          label="Type"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={5} lg={2}>
                      <TextFieldControl
                        control={control}
                        defaultValue={item.price_description}
                        label="Name"
                        name={`rebates.${index}.price_description`}
                        style={{
                          width: '100%',
                        }}
                        InputProps={{
                          classes: {
                            input: classes.mobileInputs,
                          },
                        }}
                        multiline
                        disabled={!update}
                      />
                      {rebates[index]?.price_modifier_type_name ===
                        PriceModifierTypes.REBATE && (
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '5px',
                            marginLeft: '5px',
                          }}
                        >
                          <Tooltip
                            title={
                              rebates[index]?.extraDescription ??
                              'No Rebate Information Available'
                            }
                          >
                            <HelpIcon
                              style={{
                                fontSize: '18px',
                              }}
                            />
                          </Tooltip>
                        </span>
                      )}

                      {/* Makes the alignment the same with or without the tooltip */}
                      {rebates[index]?.price_modifier_type_name !==
                        PriceModifierTypes.REBATE && (
                        <span style={{ width: '18px' }}></span>
                      )}
                    </Grid>
                    <Grid item xs={6} sm={3} lg={2}>
                      <KeyboardDatePickerControl
                        control={control}
                        label="Expiry"
                        margin="normal"
                        defaultValue={item.expiry}
                        name={`rebates.${index}.expiry`}
                        style={{
                          width: '100%',
                          minWidth: mobile ? '120px' : '',
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                        InputProps={{
                          classes: {
                            input: classes.mobileInputs,
                          },
                        }}
                        className={classes.root}
                        disabled={!update}
                      />
                    </Grid>

                    <Grid item xs={5} sm={3} lg={1}>
                      <TextFieldControl
                        control={control}
                        style={{ width: '100%' }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          classes: {
                            input: `${mobile ? '' : classes.mobileInputs}`,
                          },
                        }}
                        label="Value"
                        name={`rebates.${index}.value`}
                        type="number"
                        inputProps={{ min: 0 }}
                        defaultValue={item.value}
                        disabled={!update}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      lg={2}
                      className={classes.discountApplies}
                      justifyContent={mobile ? 'flex-start' : 'space-between'}
                    >
                      <div>
                        <Controller
                          render={({ field: { name, value, onChange } }) => (
                            <FormControlLabel
                              checked={value}
                              disabled={!canUpdateDetailPrice}
                              className={classes.responsiveCheckbox}
                              control={
                                <Checkbox
                                  size={`${mobile ? '' : 'small'}`}
                                  color="secondary"
                                />
                              }
                              label={
                                <Typography
                                  variant={mobile ? 'body2' : 'caption'}
                                >
                                  Cash
                                </Typography>
                              }
                              labelPlacement="end"
                              name={name}
                              onChange={e => onChange(e.target.checked)}
                            />
                          )}
                          name={`rebates.${index}.cash`}
                          defaultValue={item.cash}
                        />
                      </div>
                      {/* Layout Helper */}
                      {!mdDevices && mobile && (
                        <div style={{ flexBasis: '100%', height: 0 }}></div>
                      )}
                      <div>
                        <Controller
                          render={({ field: { name, value, onChange } }) => (
                            <FormControlLabel
                              checked={value}
                              disabled={!canUpdateDetailPrice}
                              className={classes.responsiveCheckbox}
                              control={
                                <Checkbox
                                  size={`${mobile ? '' : 'small'}`}
                                  color="secondary"
                                />
                              }
                              label={
                                <Typography
                                  variant={mobile ? 'body2' : 'caption'}
                                >
                                  Finance
                                </Typography>
                              }
                              labelPlacement="end"
                              name={name}
                              onChange={e => onChange(e.target.checked)}
                            />
                          )}
                          name={`rebates.${index}.finance`}
                          defaultValue={item.finance}
                        />
                      </div>
                      <div>
                        <Controller
                          render={({ field: { name, value, onChange } }) => (
                            <FormControlLabel
                              checked={value}
                              disabled={!canUpdateDetailPrice}
                              className={classes.responsiveCheckbox}
                              control={
                                <Checkbox
                                  size={`${mobile ? '' : 'small'}`}
                                  color="secondary"
                                />
                              }
                              label={
                                <Typography
                                  variant={mobile ? 'body2' : 'caption'}
                                >
                                  Lease
                                </Typography>
                              }
                              labelPlacement="end"
                              name={name}
                              onChange={e => onChange(e.target.checked)}
                            />
                          )}
                          name={`rebates.${index}.lease`}
                          defaultValue={item.lease}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={9} sm={2}>
                      <Controller
                        style={{ width: '100%' }}
                        render={({ field: { name, value, onChange } }) => (
                          <FormControlLabel
                            checked={value}
                            style={{ textAlign: 'center' }}
                            disabled={!canUpdateDetailPrice}
                            control={
                              <Checkbox
                                size={`${mobile ? '' : 'small'}`}
                                color="secondary"
                              />
                            }
                            label={
                              <Typography
                                variant={mobile ? 'body2' : 'caption'}
                              >
                                Show on Website
                              </Typography>
                            }
                            name={name}
                            onChange={e => onChange(e.target.checked)}
                            labelPlacement={lgDevices ? 'bottom' : 'end'}
                            className={classes.responsiveCheckbox}
                          />
                        )}
                        defaultValue={item.show_on_website}
                        name={`rebates.${index}.show_on_website`}
                      />
                    </Grid>
                    {/* Deleting a line does not trigger the form 
                  to update, and the option to save is disabled, unless other 
                  changes have been made. Will need to fix in future 
                  React Form version */}
                    <Grid
                      item
                      xs={2}
                      sm={1}
                      style={{
                        textAlign: `${mobileSm ? 'center' : ''}`,
                      }}
                    >
                      <Fab
                        size="small"
                        style={
                          !canUpdateDetailPrice
                            ? { backgroundColor: 'grey', color: 'white' }
                            : { backgroundColor: 'red', color: 'white' }
                        }
                        onClick={() => {
                          removeRebates(index);
                        }}
                        disabled={!canUpdateDetailPrice}
                      >
                        <DeleteIcon />
                      </Fab>
                    </Grid>
                    {fieldsRebates?.length - 1 !== index && (
                      <Grid
                        item
                        xs={12}
                        style={{
                          width: '100%',
                          paddingTop: '1.5rem',
                          order: '9',
                        }}
                      >
                        <hr
                          style={{
                            borderTop: '1px dashed #dbdbdb',
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            {fieldsRebates?.length > 0 && (
              <Box
                style={{
                  width: `${mobile ? '55%' : '100%'}`,
                  padding: '30px 0 10px 0',
                  margin: '0 auto',
                }}
              >
                <Alert
                  severity="info"
                  style={{ fontSize: `${mobile ? '0.875rem' : '0.75rem'}` }}
                >
                  Bi-weekly payments will be adjusted once changes have been
                  saved.
                </Alert>
              </Box>
            )}
          </>
        )}
      </Box>
      <Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '20px',
            columnGap: '20px',
          }}
        >
          <Typography style={{ fontWeight: 'bold' }}>
            Multi-Line Pricing
          </Typography>
          <Fab
            disabled={!canUpdateDetailPrice}
            size="small"
            onClick={() => {
              setAddNewLine(true);
              append({
                // These do not appear to be setting the default values, other
                // than price_modifier_type_name.
                // Note that the default expiry in KeyboardDatePicker is
                // today's date, BUT ALSO TODAY'S TIME.
                cash: true,
                expiry:
                  detailedPricingNoRebates &&
                  detailedPricingNoRebates?.length > 0
                    ? detailedPricingNoRebates[
                        detailedPricingNoRebates?.length - 1
                      ]?.expiry
                    : null,
                finance: true,
                lease: false,
                price_description: '',
                price_modifier_type_name: PriceModifierTypes.ADDON,
                program_id: 'user-created',
                value: 0,
                show_on_website: false,
              });
            }}
            style={{
              display: 'flex',
              backgroundColor: canUpdateDetailPrice ? '#5bc236' : 'grey',
              color: 'white',
            }}
          >
            <AddIcon />
          </Fab>
        </Box>

        {(!detailedPricingNoRebates?.length || addNewLine === false) && (
          <Box
            style={{
              paddingBottom: '1rem',
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '1rem',
            }}
          >
            Please add a price line to modify special pricing!
          </Box>
        )}

        {(detailedPricingNoRebates?.length || addNewLine === true) && (
          <>
            {fields
              .filter(
                x => x.price_modifier_type_name !== PriceModifierTypes.REBATE,
              )
              .map((item, index) => {
                return (
                  <Grid
                    container
                    key={item.key}
                    justifyContent={mdDevices ? 'space-between' : 'flex-start'}
                    className={classes.priceLine}
                    spacing={lgDevices ? 1 : mobile ? 3 : 2}
                  >
                    {!mobile && (
                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingBottom: '10px',
                          fontSize: '14px',
                          textDecorationLine: 'underline',
                          fontWeight: 'bold',
                        }}
                      >
                        Detailed Price #{index + 1}
                      </Grid>
                    )}

                    {/* Include id if the item already exists */}
                    <TextFieldControl
                      control={control}
                      defaultValue={item.id}
                      name={`detailed_pricing_no_rebates.${index}.id`}
                      style={{ display: 'none' }}
                    />
                    <TextFieldControl
                      control={control}
                      disabled={true}
                      name="special_price_expires"
                      style={{ display: 'none' }}
                    />
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      md={2}
                      lg={1}
                      style={{ marginRight: lgDevices ? '15px' : 0 }}
                    >
                      <FormControl
                        style={{
                          width: lgDevices ? '120%' : '100%',
                        }}
                      >
                        <SelectControl
                          control={control}
                          defaultValue={item.price_modifier_type_name}
                          name={`detailed_pricing_no_rebates.${index}.price_modifier_type_name`}
                          className={classes.mobileInputs}
                          options={[
                            { name: 'Add-on', value: PriceModifierTypes.ADDON },
                            {
                              name: 'Discount',
                              value: PriceModifierTypes.DISCOUNT,
                            },
                          ]}
                          label="Type"
                          noNull
                          disabled={!update}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={5} lg={2}>
                      <TextFieldControl
                        multiline
                        control={control}
                        defaultValue={item.price_description}
                        label="Name"
                        name={`detailed_pricing_no_rebates.${index}.price_description`}
                        style={{
                          width: '100%',
                        }}
                        InputProps={{
                          classes: {
                            input: classes.mobileInputs,
                          },
                        }}
                        disabled={!update}
                      />

                      {detailedPricingNoRebates[index]
                        ?.price_modifier_type_name ===
                        PriceModifierTypes.REBATE && (
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '5px',
                            marginLeft: '5px',
                          }}
                        >
                          <Tooltip
                            title={
                              detailedPricingNoRebates[index]
                                ?.extraDescription ??
                              'No Rebate Information Available'
                            }
                          >
                            <HelpIcon
                              style={{
                                fontSize: '18px',
                              }}
                            />
                          </Tooltip>
                        </span>
                      )}

                      {/* Makes the alignment the same with or without the tooltip */}
                      {detailedPricingNoRebates[index]
                        ?.price_modifier_type_name !==
                        PriceModifierTypes.REBATE && (
                        <span style={{ width: '18px' }}></span>
                      )}
                    </Grid>
                    <Grid item xs={6} sm={3} lg={2}>
                      <KeyboardDatePickerControl
                        control={control}
                        label="Expiry"
                        margin="normal"
                        defaultValue={item.expiry}
                        name={`detailed_pricing_no_rebates.${index}.expiry`}
                        style={{
                          width: '100%',
                          minWidth: mobile ? '120px' : '',
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                        InputProps={{
                          classes: {
                            input: classes.mobileInputs,
                          },
                        }}
                        className={classes.root}
                        disabled={!update}
                      />
                    </Grid>

                    <Grid item xs={5} sm={3} lg={1}>
                      <TextFieldControl
                        style={{ width: '100%' }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          classes: {
                            input: `${mobile ? '' : classes.mobileInputs}`,
                          },
                        }}
                        label="Value"
                        name={`detailed_pricing_no_rebates.${index}.value`}
                        type="number"
                        inputProps={{ min: 0 }}
                        defaultValue={item.value}
                        disabled={!update}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      sm={3}
                      lg={2}
                      className={classes.discountApplies}
                      justifyContent={'space-between'}
                    >
                      <div>
                        <Controller
                          render={({ field: { name, value, onChange } }) => (
                            <FormControlLabel
                              checked={value}
                              disabled
                              className={classes.responsiveCheckbox}
                              control={
                                <Checkbox
                                  size={`${mobile ? '' : 'small'}`}
                                  color="secondary"
                                />
                              }
                              label={
                                <Typography
                                  variant={mobile ? 'body2' : 'caption'}
                                >
                                  Cash
                                </Typography>
                              }
                              labelPlacement="end"
                              name={name}
                              onChange={e => onChange(e.target.checked)}
                            />
                          )}
                          name={`detailed_pricing_no_rebates.${index}.cash`}
                          defaultValue={item.cash}
                        />
                      </div>
                      <div>
                        <Controller
                          render={({ field: { name, value, onChange } }) => (
                            <FormControlLabel
                              checked={value}
                              disabled
                              className={classes.responsiveCheckbox}
                              control={
                                <Checkbox
                                  size={`${mobile ? '' : 'small'}`}
                                  color="secondary"
                                />
                              }
                              labelPlacement="end"
                              label={
                                <Typography
                                  variant={mobile ? 'body2' : 'caption'}
                                >
                                  Finance
                                </Typography>
                              }
                              name={name}
                              onChange={e => onChange(e.target.checked)}
                            />
                          )}
                          name={`detailed_pricing_no_rebates.${index}.finance`}
                          defaultValue={item.finance}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={9} sm={3} md={2}>
                      <Controller
                        style={{ width: '100%' }}
                        render={({ field: { name, value, onChange } }) => (
                          <FormControlLabel
                            checked={value}
                            style={{ textAlign: 'center' }}
                            disabled={!canUpdateDetailPrice}
                            control={
                              <Checkbox
                                size={`${mobile ? '' : 'small'}`}
                                color="secondary"
                              />
                            }
                            label={
                              <Typography
                                variant={mobile ? 'body2' : 'caption'}
                              >
                                Show on Website
                              </Typography>
                            }
                            name={name}
                            onChange={e => onChange(e.target.checked)}
                            labelPlacement={lgDevices ? 'bottom' : 'end'}
                            className={classes.responsiveCheckbox}
                          />
                        )}
                        defaultValue={item.show_on_website}
                        name={`detailed_pricing_no_rebates.${index}.show_on_website`}
                      />
                    </Grid>
                    {/* Deleting a line does not trigger the form 
                  to update, and the option to save is disabled, unless other 
                  changes have been made. Will need to fix in future 
                  React Form version */}
                    <Grid
                      item
                      xs={2}
                      sm={1}
                      style={{
                        textAlign: `${mobileSm ? 'center' : ''}`,
                      }}
                    >
                      <Fab
                        size="small"
                        style={
                          !canUpdateDetailPrice
                            ? { backgroundColor: 'grey', color: 'white' }
                            : { backgroundColor: 'red', color: 'white' }
                        }
                        onClick={() => {
                          remove(index);
                        }}
                        disabled={!canUpdateDetailPrice}
                      >
                        <DeleteIcon />
                      </Fab>
                    </Grid>
                    {fields.length - 1 !== index && !mobile && (
                      <Grid
                        item
                        xs={12}
                        style={{
                          width: '100%',
                          paddingTop: '1.5rem',
                          order: '9',
                        }}
                      >
                        <hr
                          style={{
                            borderTop: '1px dashed #dbdbdb',
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            {fields.length > 0 && (
              <Box
                style={{
                  width: `${mobile ? '55%' : '100%'}`,
                  padding: '30px 0 10px 0',
                  margin: '0 auto',
                }}
              >
                <Alert
                  severity="info"
                  style={{ fontSize: `${mobile ? '0.875rem' : '0.75rem'}` }}
                >
                  Bi-weekly payments will be adjusted once changes have been
                  saved.
                </Alert>
              </Box>
            )}
          </>
        )}
      </Box>
      <Box
        bgcolor="#EAE9E9"
        padding={mobile ? '10px 20px 10px 0' : '10px 5px'}
        fontSize={mobile ? '1rem' : '0.75rem'}
        display="flex"
        justifyContent="center"
      >
        {/* *** Totals *** */}
        <TableContainer style={{ width: '90%' }}>
          <Table size={mobile ? 'medium' : 'small'} className={classes.root}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  Cash
                </TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  Finance
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  align="right"
                  className={classes.tableHeader}
                  style={{
                    maxWidth: `${mobile ? '' : '65px'}`,
                  }}
                >
                  Total Add-Ons:
                </TableCell>
                <TableCell align="center">
                  +{' '}
                  {formatPrice(
                    cashAddonTotal(
                      detailedPricing,
                      me?.goUserProfile?.settings?.timezone,
                    ),
                  )}
                </TableCell>
                <TableCell align="center">
                  +{' '}
                  {formatPrice(
                    financeAddonTotal(
                      detailedPricing,
                      me?.goUserProfile?.settings?.timezone,
                    ),
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="right"
                  className={classes.tableHeader}
                  style={{ maxWidth: `${mobile ? '' : '65px'}` }}
                >
                  Total Discounts:
                </TableCell>
                <TableCell align="center">
                  -{' '}
                  {formatPrice(
                    cashDiscountTotal(
                      detailedPricing,
                      me?.goUserProfile?.settings?.timezone,
                    ) +
                      cashRebateTotal(
                        detailedPricing,
                        me?.goUserProfile?.settings?.timezone,
                      ),
                  )}
                </TableCell>
                <TableCell align="center">
                  -{' '}
                  {formatPrice(
                    financeDiscountTotal(
                      detailedPricing,
                      me?.goUserProfile?.settings?.timezone,
                    ) +
                      financeRebateTotal(
                        detailedPricing,
                        me?.goUserProfile?.settings?.timezone,
                      ),
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="right"
                  className={classes.tableHeader}
                  style={{ maxWidth: `${mobile ? '' : '65px'}` }}
                >
                  Total:
                </TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  {formatPrice(cashPrice)}
                </TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  {formatPrice(financePrice)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box style={{ padding: '5px 0' }}>
        <Typography variant={mobile ? 'body2' : 'caption'}>
          Adding in a default down payment will apply this to the payment
          calculations.
        </Typography>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          columnGap: '15px',
          alignItems: 'center',
        }}
      >
        <InputLabel style={{ fontSize: `${mobile ? '1rem' : '0.875rem'}` }}>
          Default Down Payment:
        </InputLabel>
        <TextFieldControl
          control={control}
          InputProps={dollarStart}
          name={`default_down_payment`}
          type="number"
          inputProps={{ min: 0 }}
          style={{ width: '30%', paddingBottom: `${mobile ? 0 : '5px'}` }}
          disabled={!update}
        />
      </Box>
    </Container>
  );
};

UsedDetailedPricing.fragments = {
  vehicle: gql`
    fragment DetailedPricingVehicle on GreaseInventoryVehicle {
      detailed_pricing {
        id
        cash
        expiry
        finance
        price_description
        price_modifier_type_name
        program_id
        show_on_website
        value
      }
    }
  `,
};

export default UsedDetailedPricing;
