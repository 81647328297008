import React, { useState } from 'react';

/* external */
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';
import moment from 'moment-timezone';

/* Material UI */
import {
  Backdrop,
  Box,
  Button,
  Chip,
  IconButton,
  Typography,
  useTheme,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

/* internal */
import {
  AppraisalStatus,
  AppraisalStatusColours,
  OfferStatus,
} from 'modules/used_vehicles/const';
import { errorHandler } from '../utils';
import { useUserContext } from 'components/MaterialUI/UserContext';

const CLAIM_OFFER = gql`
  mutation claimOffer($offerId: Int!) {
    appraisals {
      claimOffer(id: $offerId) {
        id
        claimedBy
        claimedByUser {
          display_name
        }
      }
    }
  }
`;

const ASSIGN_OFFER = gql`
  mutation assignOffer($offerId: Int!, $claimedBy: String!) {
    appraisals {
      assignOffer(id: $offerId, claimedBy: $claimedBy, isAssigned: true) {
        id
        claimedBy
        claimedByUser {
          display_name
        }
      }
    }
  }
`;

const StatusChip = ({ color, statusText, ...rest }) => {
  return (
    <Chip
      variant="outlined"
      style={{ color, borderColor: color }}
      label={statusText}
      {...rest}
    />
  );
};

const DashboardCardStatus = ({
  appraisal: {
    appraisalStatus,
    auctionItem,
    boughtNowAt,
    inTransit,
    isCustomerAppraisal,
    isQuestionsPending,
    deliveredAt,
    soldAt,
  },
  dealer, // dealer or wholesaler - still not sure if I should just split the
  // DashboardCard component into dealer and wholesaler?
  showIcon,
  showChip,
  statusChipProps = {},
  offer,
  users,
  usersLoading,
  canClaimOrAssignOffer,
  ...rest
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { auctionId } = auctionItem ?? {};
  const { currentUser } = useUserContext();
  let statusText = appraisalStatus;
  let color = AppraisalStatusColours[appraisalStatus];
  let icon = null;
  let iconColour = 'black';
  let onClick = null;
  const customerOfferedStatus = 'CUSTOMER OFFERED';
  const [userToAssign, setUserToAssign] = useState(currentUser?.username);
  const [openUsersToAssignModal, setOpenUsersToAssignModal] = useState(false);

  const handleAssignUser = e => {
    setUserToAssign(e.target.value);
  };

  const [claimOffer] = useMutation(CLAIM_OFFER);
  const [assignOffer] = useMutation(ASSIGN_OFFER);

  const handleClaimOffer = e => {
    e.stopPropagation();
    claimOffer({
      variables: { offerId: offer.id },
    }).then(
      () => {
        enqueueSnackbar('Offer claimed successfully', { variant: 'success' });
      },
      err => errorHandler(enqueueSnackbar, () => {})(err),
    );
  };

  const handleAssignOffer = e => {
    // Logic for Mutation to AssignOffer with an Offer ID and a username
    e.stopPropagation();
    assignOffer({
      variables: { offerId: offer.id, claimedBy: userToAssign },
    }).then(
      () => {
        enqueueSnackbar('Offer assigned successfully', { variant: 'success' });
        setOpenUsersToAssignModal(false);
      },
      err => errorHandler(enqueueSnackbar, () => {})(err),
    );
  };

  const onClose = () => {
    setOpenUsersToAssignModal(false);
  };

  const deliveryDelayed =
    !deliveredAt &&
    soldAt &&
    moment().subtract(7, 'days').isAfter(moment(soldAt));

  if (offer?.isQuestionsPending || (dealer && isQuestionsPending)) {
    icon = 'fas fa-question-circle';
    iconColour = 'blue';
  }

  if (dealer) {
    if (appraisalStatus === AppraisalStatus.SOLD)
      if (isCustomerAppraisal) statusText = `BOUGHT FROM CUSTOMER`;
      else statusText = `BOUGHT${boughtNowAt ? ' (NOW)' : ''}`;
    if (appraisalStatus === AppraisalStatus.AUCTIONED)
      statusText = 'BOUGHT AT AUCTION';
    if (
      appraisalStatus === AppraisalStatus.PENDING_OFFER &&
      offer.offerStatus === OfferStatus.CUSTOMER_OFFERED
    )
      statusText = 'CUSTOMER OFFERED';
  } else {
    if (appraisalStatus === AppraisalStatus.PENDING_AUCTION) {
      icon = 'fas fa-gavel';
      onClick = e => {
        if (auctionId) history.push(`/auctions/${auctionId}`);
        e.stopPropagation();
        e.preventDefault();
      };
    } else if (appraisalStatus === AppraisalStatus.SOLD)
      statusText = `${appraisalStatus}${boughtNowAt ? ' (NOW)' : ''}`;
  }

  // Show delivery status if sold or auctioned
  if (
    [AppraisalStatus.SOLD, AppraisalStatus.AUCTIONED].includes(appraisalStatus)
  ) {
    if (deliveredAt) {
      icon = 'fas fa-check-circle';
      iconColour = 'mediumseagreen';
    } else {
      icon = 'fas fa-exclamation-triangle';
      if (deliveryDelayed || !dealer) iconColour = 'red';
      else iconColour = 'orange';
    }
  }

  return (
    <Box
      paddingBottom="0.5rem"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      {...rest}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {showChip && statusText && (
          <Box>
            <StatusChip
              statusText={statusText}
              color={color}
              {...statusChipProps}
            />
          </Box>
        )}
        {canClaimOrAssignOffer && (
          <>
            {offer?.offerStatus === customerOfferedStatus &&
              !offer?.claimedByUser && (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: theme.colours.add,
                      marginLeft: '10px',
                    }}
                    onClick={e => handleClaimOffer(e)}
                  >
                    Claim
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: theme.actions.confirm,
                      marginLeft: '10px',
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      setOpenUsersToAssignModal(true);
                    }}
                  >
                    Assign
                  </Button>
                  <Tooltip title={'Open Offer In New Tab'}>
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        window.open(
                          `/used-vehicles/offers/${offer.id}`,
                          '_blank',
                        );
                      }}
                      size="large"
                    >
                      <OpenInNewIcon style={{ fontSize: '22px' }} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
          </>
        )}
        {offer?.claimedByUser && (
          <Typography variant="body2" sx={{ marginLeft: '10px' }}>
            Claimed by: {offer.claimedByUser.display_name}
          </Typography>
        )}
      </Box>
      {showIcon && (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {icon && (
            <Box fontSize="20px" color={iconColour || color}>
              {onClick ? (
                <IconButton onClick={onClick} size="large">
                  <i className={icon} />
                </IconButton>
              ) : (
                <i className={icon} />
              )}
            </Box>
          )}
          {inTransit &&
            [
              AppraisalStatus.OWNED,
              AppraisalStatus.SOLD,
              AppraisalStatus.AUCTIONED,
              AppraisalStatus.PENDING_OFFER,
            ].includes(appraisalStatus) && (
              /* pad if the icon is clickable to ensure the right edges line up */
              <Box paddingRight={onClick ? '12px' : ''}>
                <i
                  className="fas fa-shipping-timed"
                  style={{ fontSize: '16px', paddingRight: '5px' }}
                />
                <span style={{ fontSize: '12px' }}>In Transit</span>
              </Box>
            )}
        </Box>
      )}
      <Backdrop
        style={{
          zIndex: 1000,
          color: '#fff',
        }}
        open={openUsersToAssignModal}
      />
      <Dialog
        maxWidth="sm"
        open={openUsersToAssignModal}
        onClose={() => {
          setOpenUsersToAssignModal(false);
        }}
      >
        <DialogTitle>Assign Reps</DialogTitle>
        <DialogContent style={{ minWidth: '200px' }}>
          <Grid container direction="row" spacing={0} alignItems="stretch">
            <Grid item xs={10} style={{ marginBottom: '10px', width: '500px' }}>
              <InputLabel>Sales Person</InputLabel>
              <Select
                value={userToAssign}
                // All of this is needed to avoid bubbling up of events.
                // If theres a more elegant way of doing this lmk - Marissa
                onBlur={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onChange={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleAssignUser(e);
                }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                style={{ width: '85%' }}
                placeholder="Assign"
              >
                <MenuItem value={''} disabled>
                  Assign
                </MenuItem>
                {users &&
                  users.length > 0 &&
                  users
                    .sort((a, b) => a.username.localeCompare(b.username))
                    .map(user => (
                      <MenuItem key={user.username} value={user.username}>
                        {user.display_name}
                      </MenuItem>
                    ))}
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            style={theme.actions.confirm}
            onClick={e => {
              e.stopPropagation();
              handleAssignOffer(e);
            }}
            variant="contained"
            color="primary"
            disabled={usersLoading || userToAssign === ''}
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// not used:
// arrived_at
// is_customer_appraisal
// offered_to_dealer_id
// purchasing_dealer_id
DashboardCardStatus.fragments = {
  appraisal: gql`
    fragment DashboardCardStatusAppraisal on Appraisal {
      appraisalStatus
      arrivedAt
      auctionItem {
        id
        auctionId
      }
      boughtNowAt
      inTransit
      isCustomerAppraisal
      isQuestionsPending
      offeredToDealerId
      purchasingDealerId
      deliveredAt
      soldAt
    }
  `,
  offer: gql`
    fragment DashboardCardStatusOffer on Offer {
      id
      claimedByUser {
        display_name
      }
      offerStatus
    }
  `,
};
export default DashboardCardStatus;
