import React, { useState } from 'react';

/* external */
import { isEmpty } from 'lodash';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { SimpleNoteCard } from 'modules/appraisals/components/';

/* Material UI */
import { useTheme } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';

/* internal */
import { AppraisalStatus, OfferStatus } from 'modules/used_vehicles/const';
import {
  formatPrice,
  formatOdometer,
  getFullDateTime,
  withSuffix,
} from 'utils';
import { displayName, formatPhoto, goodOrBad, yesOrNo } from '../utils';
import { paveReportLink } from 'modules/appraisals/utils';
import { TAGS } from 'constants.js';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { useUserContext } from 'components/MaterialUI/UserContext';
import BuyNowListItem from './BuyNowListItem';
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import ImageCarousel from './ImageCarousel';
import Loading from 'components/MaterialUI/Loading';
import AddAppraisalBuyerNoteModal from 'modules/appraisals/components/AddAppraisalBuyerNoteModal';

const useStyles = makeStyles(theme => ({
  addNote: {
    padding: '0px',
    margin: '0px 5px 0px 10px',
  },
  paper: {
    padding: '10px',
    margin: '10px',
    minHeight: '200px',
  },
  card: {
    marginBottom: '15px',
    marginTop: '15px',
  },
}));

const AccordionSummary = withStyles({
  expanded: {
    margin: '0 !important',
  },
})(MuiAccordionSummary);

const RESOLVE_QUESTIONS = gql`
  mutation resolveOfferQuestions($id: Int!) {
    appraisals {
      resolveOfferQuestions(id: $id) {
        id
        appraisal {
          id
          isQuestionsPending
        }
      }
    }
  }
`;

const ANSWER_QUESTION = gql`
  mutation answerQuestion($message: MessageInput!) {
    appraisals {
      createMessage(message: $message) {
        id
      }
    }
  }
`;

const ASK_QUESTION = gql`
  mutation askQuestion($message: MessageInput!) {
    appraisals {
      createMessage(message: $message) {
        id
      }
    }
  }
`;

const ATTACHMENT_QUERY = gql`
  query attachments($vin: String!) {
    attachment {
      getAttachments(vin: $vin) {
        id
        tags
        label
        created_by
        date_created
        url
        user {
          display_name
        }
      }
    }
  }
`;

const DELETE_ATTACHMENT_MUTATION = gql`
  mutation attachments($id: Int!) {
    attachment {
      deleteAttachment(id: $id) {
        id
      }
    }
  }
`;

const ADD_ATTACHMENT_MUTATION = gql`
  mutation attachments(
    $dataURI: String!
    $label: String!
    $tags: String!
    $vin: String!
    $dealer_id: Int!
    $created_by: String!
    $app_id: String!
    $extension: String!
  ) {
    attachment {
      createAttachment(
        dataURI: $dataURI
        label: $label
        tags: $tags
        vin: $vin
        dealer_id: $dealer_id
        created_by: $created_by
        app_id: $app_id
        extension: $extension
      ) {
        id
      }
    }
  }
`;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
}

const AppraisalDetails = ({
  appraisal,
  dealer = false,
  refetch = () => {},
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tags, setTags] = useState('');
  const [upload, setUpload] = useState({
    name: '',
    dataURI: '',
    extension: '',
  });
  const [label, setLabel] = useState('');
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();
  const { dealerId } = useDealerContext();
  const [answer, setAnswer] = useState();
  const [question, setQuestion] = useState();
  const { currentUser } = useUserContext();

  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const buyNowOffers = appraisal.offers.filter(
    ({ offerStatus }) => offerStatus === OfferStatus.BUY_NOW_REQUEST,
  );

  const [resolveQuestions, resolveQuestionsStatus] =
    useMutation(RESOLVE_QUESTIONS);

  const [answerQuestion] = useMutation(ANSWER_QUESTION);
  const [askQuestion] = useMutation(ASK_QUESTION);

  const {
    loading,
    data,
    refetch: attachmentRefetch,
  } = useQuery(ATTACHMENT_QUERY, {
    variables: { vin: appraisal.vin },
    skip: !appraisal.vin,
  });
  const attachments = (data && data.attachment.getAttachments) || [];

  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT_MUTATION, {
    onCompleted: () => attachmentRefetch(),
  });
  const [createAttachment, { loading: mutationLoading }] = useMutation(
    ADD_ATTACHMENT_MUTATION,
    {
      onCompleted: () => attachmentRefetch(),
    },
  );

  const handleTags = event => {
    setTags(event.target.value);
  };

  const handleLabel = event => {
    setLabel(event.target.value);
  };

  const resetForm = () => {
    setTags('');
    setUpload({ name: '', dataURI: '' });
    setLabel('');
  };

  async function handleChange(event) {
    const files = event.target.files;
    const extension = files[0].name.split('.').slice(-1)[0];
    let base64file = await getBase64(files[0]);
    setUpload({
      name: files[0].name,
      dataURI: base64file,
      extension,
    });
  }

  const {
    appraisalStatus,
    bodyType,
    buyNowPrice,
    contactEmail,
    contactName,
    contactPhone,
    customerMotive,
    customerPreferredContactMethod,
    deliveredAt,
    exteriorColour,
    hasLien,
    hasOpi,
    interiorColour,
    isDelivered,
    isGlassGood,
    isPaveSessionComplete,
    isQuestionsPending,
    isTiresGood,
    notes,
    odometer,
    paveSessionId,
    offers = [],
    photos = [],
    price,
    soldAt,
    trimVariation,
    vin,
  } = appraisal;

  // TODO: shouldn't have to retrieve all the offers to get the pending
  // offer.  Especially in the case this is called from offer detail.
  const pendingOffer = offers
    ?.sort((a, b) => a.order - b.order)
    .find(offer => !offer.declinedAt && !offer.rescindedAt);

  const questions =
    pendingOffer?.questions.sort((a, b) =>
      a.createdAt.localeCompare(b.createdAt),
    ) || [];

  const sendAnswerHandler = questionId => () =>
    answerQuestion({
      variables: {
        message: {
          offerId: pendingOffer.id,
          questionId,
          text: answer,
          toDealerId: pendingOffer.dealerId,
          fromDealerId: dealerId,
        },
      },
    }).then(() => {
      setAnswer(null);
      refetch();
    }); // TODO: error handling

  const handleAskQuestion = () =>
    askQuestion({
      variables: {
        message: {
          offerId: pendingOffer.id,
          text: question,
          toDealerId: appraisal.dealerId,
          fromDealerId: dealerId,
          isQuestion: true,
        },
      },
    }).then(() => {
      setQuestion('');
      refetch();
    });

  const deliveryDelayed =
    !deliveredAt &&
    soldAt &&
    moment().subtract(7, 'days').isAfter(moment(soldAt));

  const handleClickOpen = index => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <LoadingBackdrop open={resolveQuestionsStatus.loading}>
        Setting questions answered!
      </LoadingBackdrop>
      {/* Show the price if it is pending offers or sold. */}
      {[AppraisalStatus.PENDING_OFFER, AppraisalStatus.SOLD].includes(
        appraisalStatus,
      ) && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          padding={1}
        >
          <Typography variant="h6">
            Price: <b>{formatPrice(price)}</b>
          </Typography>
          <Box fontSize="20px">
            {appraisalStatus === AppraisalStatus.SOLD ? (
              isDelivered ? (
                <i
                  style={{ color: 'mediumseagreen' }}
                  className="fas fa-check-circle"
                />
              ) : (
                <i
                  style={{ color: deliveryDelayed ? 'red' : 'orange' }}
                  className="fas fa-exclamation-triangle"
                />
              )
            ) : isQuestionsPending ? (
              <i style={{ color: 'blue' }} className="fas fa-question-circle" />
            ) : null}
          </Box>
        </Box>
      )}
      <Box margin={1}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Appraisal Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column">
              <Grid item>
                <Typography style={{ width: '100%' }}>
                  Notes/Damage Disclosure:
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    fontSize: '14px',
                    color: 'grey',
                    whiteSpace: 'pre-line',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                  }}
                >
                  {notes}
                </Typography>
                <Typography
                  style={{
                    fontSize: '14px',
                    color: 'grey',
                    whiteSpace: 'pre-line',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                  }}
                >
                  <b>Contact Info:</b> <br />
                  Email: {contactEmail} <br />
                  Name: {contactName} <br />
                  Phone: {contactPhone} <br />
                  Preferred Contact: {customerPreferredContactMethod} <br />
                  Sell/Trade: {customerMotive}
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
          {isPaveSessionComplete && paveSessionId && (
            <AccordionDetails>
              <Grid container direction="column">
                <Grid item>
                  <Link
                    style={{ textUnderlineOffset: '0.3em' }}
                    href={paveReportLink(paveSessionId)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box display="flex" alignItems="center">
                      View PAVE Report{' '}
                      <Icon
                        className="fas fa-road"
                        style={{ width: 'auto', marginLeft: '5px' }}
                      />
                    </Box>
                  </Link>
                </Grid>
              </Grid>
            </AccordionDetails>
          )}
        </Accordion>
      </Box>
      <Box margin={1}>
        <Accordion defaultExpanded={dealer}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Vehicle Details</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: 0 }}>
            <Grid container direction="column">
              <Grid item>
                <Typography style={{ width: '100%' }}>
                  <b>{displayName(appraisal)}</b>{' '}
                  <span style={{ color: '#999999', fontSize: '12px' }}>
                    {trimVariation}
                  </span>
                </Typography>
              </Grid>
              <Grid item style={{ paddingBottom: '1rem' }}>
                {vin}
              </Grid>
              <Grid
                item
                container
                style={{ paddingBottom: '1rem', fontWeight: 'bold' }}
                justifyContent="space-between"
              >
                <Grid item>
                  <Box>Body Style: {bodyType}</Box>
                  <Box>Odometer: {formatOdometer(odometer)}</Box>
                </Grid>
                <Grid item style={{ paddingRight: '2em' }}>
                  <Box>Exterior: {exteriorColour}</Box>
                  <Box>Interior: {interiorColour}</Box>
                </Grid>
              </Grid>
              <Grid
                container
                item
                style={{ height: '60px', fontSize: '16px' }}
                spacing={2}
              >
                <Grid item>
                  <Box>
                    <Box component="span" fontWeight="bold">
                      Glass:{' '}
                    </Box>
                    <Box component="span">{goodOrBad(isGlassGood)}</Box>
                  </Box>
                  <Box>
                    <Box component="span" fontWeight="bold">
                      Tires:{' '}
                    </Box>
                    <Box component="span">{goodOrBad(isTiresGood)}</Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <Box component="span" fontWeight="bold">
                      OPI:{' '}
                    </Box>
                    <Box component="span">{yesOrNo(hasOpi)}</Box>
                  </Box>
                  <Box>
                    <Box component="span" fontWeight="bold">
                      Lien:{' '}
                    </Box>
                    <Box component="span">{yesOrNo(hasLien)}</Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item>
                <div
                  style={{ width: '100%', paddingBottom: '1rem' }}
                  className="carproof detail carproof-badge"
                  data-vin={vin}
                  data-type="slim"
                  data-language="en"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box margin={1}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Photos</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row">
              {isEmpty(photos) && (
                <Box margin={1} textAlign="left">
                  No photos uploaded!
                </Box>
              )}
              {photos.map((photo, index) => (
                <Grid
                  item
                  onClick={() => handleClickOpen(index)}
                  key={photo.id}
                >
                  <img
                    alt={photo.id}
                    style={{
                      objectFit: 'cover',
                      height: '100px',
                      width: '125px',
                      marginBottom: '10px',
                      marginRight: '10px',
                    }}
                    src={formatPhoto(photo)}
                  />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box margin={1}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Attachments</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <form style={{ paddingTop: '1rem' }}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        style={{
                          maxWidth: '150px',
                          width: '100%',
                        }}
                        value={upload.name || ''}
                        disabled
                      />
                      <span
                        style={{
                          paddingLeft: '1rem',
                        }}
                      >
                        <input
                          style={{ display: 'none' }}
                          id="attachment-file"
                          multiple
                          type="file"
                          onChange={handleChange}
                        />
                        <label htmlFor="attachment-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            <FolderIcon style={{ paddingRight: '5px' }} />{' '}
                            BROWSE
                          </Button>
                        </label>
                      </span>
                      <div style={{ padding: '1rem 0 1rem 0' }}>
                        <TextField
                          id="outlined-basic"
                          label="Label"
                          size="small"
                          style={{
                            maxWidth: '250px',
                            width: '100%',
                          }}
                          value={label}
                          onChange={handleLabel}
                        />
                      </div>
                      <FormControl>
                        <InputLabel id="attachment-tags">Tags</InputLabel>
                        <Select
                          labelId="attachment-tags"
                          value={tags}
                          onChange={handleTags}
                          style={{ width: '250px' }}
                        >
                          {TAGS.map(([key, value]) => {
                            return (
                              <MenuItem value={key} key={key}>
                                {value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <div style={{ paddingTop: '1rem' }}>
                        {!mutationLoading && (
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            onClick={() =>
                              appraisal.vin && appraisal.vin !== ''
                                ? tags !== ''
                                  ? createAttachment({
                                      variables: {
                                        dataURI: upload.dataURI,
                                        label,
                                        tags,
                                        vin: appraisal.vin,
                                        dealer_id: dealerId,
                                        created_by: currentUser.username,
                                        app_id: 'smi.inventory',
                                        extension: upload.extension,
                                      },
                                    })
                                      .catch(e => {
                                        console.log(e);
                                      })
                                      .then(r => {
                                        const attachmentCreated =
                                          r &&
                                          r.data.attachment.createAttachment.id;

                                        if (
                                          attachmentCreated &&
                                          tags === 'UVI'
                                        ) {
                                          enqueueSnackbar(
                                            `Used Vehicle Inspection Successfully created!`,
                                          );
                                        } else {
                                          attachmentCreated
                                            ? enqueueSnackbar(
                                                `Attachment Successfully created!`,
                                              )
                                            : enqueueSnackbar(
                                                `Error creating attachment. Please make sure all data is provided in the fields.`,
                                              );
                                        }
                                      })
                                      .then(() => resetForm())
                                  : enqueueSnackbar(
                                      `Please add a tag to upload an attachment.`,
                                    )
                                : enqueueSnackbar(
                                    `Please provide a VIN to upload an attachment`,
                                  )
                            }
                          >
                            Upload
                          </Button>
                        )}
                      </div>
                    </form>
                    {mutationLoading && (
                      <>
                        <Box>
                          <CircularProgress /> Uploading Attachment.. Please
                          Wait.
                        </Box>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Table
                      style={{ minWidth: 300 }}
                      aria-label="spanning table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>File</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>By</TableCell>
                        </TableRow>
                      </TableHead>
                      {loading && (
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Loading />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                      {!loading && (
                        <TableBody>
                          {attachments.map((attachment, key) => (
                            <TableRow key={key}>
                              <TableCell>
                                <a href={attachment.url}>{attachment.label}</a>
                                <Typography
                                  style={{
                                    whiteSpace: 'pre-line',
                                    fontSize: '12px',
                                    color: '#999999',
                                  }}
                                >
                                  {attachment.tags}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {getFullDateTime(
                                  withSuffix(attachment.date_created, 'Z'),
                                  currentUser?.goUserProfile?.settings
                                    ?.timezone,
                                )}
                              </TableCell>
                              <TableCell>
                                {attachment.user.display_name}
                                <IconButton
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                  }}
                                  onClick={() =>
                                    deleteAttachment({
                                      variables: { id: attachment.id },
                                    }).then(r =>
                                      r.data.attachment.deleteAttachment.id
                                        ? enqueueSnackbar(
                                            `Attachment Successfully deleted!`,
                                          )
                                        : enqueueSnackbar(
                                            `Error deleting attachment.`,
                                          ),
                                    )
                                  }
                                  size="large"
                                >
                                  <DeleteIcon style={{ color: 'red' }} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box margin={1}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Notes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: '100%' }}>
              <Dialog
                maxWidth="sm"
                open={openModal}
                onClose={() => setOpenModal(false)}
                fullWidth
              >
                <AddAppraisalBuyerNoteModal
                  handleClose={() => setOpenModal(false)}
                  onSave={refetch}
                  appraisalId={appraisal?.id}
                  dealerId={dealerId}
                />
              </Dialog>
              <Paper className={classes.paper}>
                <span style={{ display: 'flex', padding: '10px' }}>
                  <h3>Appraisal Notes</h3>
                  <IconButton
                    className={classes.addNote}
                    onClick={() => setOpenModal(true)}
                    size="small"
                  >
                    <AddIcon style={theme.actions.add} />
                  </IconButton>
                </span>
                {appraisal?.buyernotes.length === 0 &&
                  'There are no notes for this appraisal'}
                {appraisal?.buyernotes.map(buyernote => (
                  <Card className={classes.card} key={buyernote?.id}>
                    <SimpleNoteCard buyernote={buyernote} />
                  </Card>
                ))}
              </Paper>
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>
      {!isEmpty(buyNowOffers) && (
        <Box margin={1}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Buy Now Requests</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width="100%">
                {buyNowOffers.map(({ id, dealer }) => (
                  <BuyNowListItem
                    id={id}
                    dealer={dealer}
                    key={id}
                    buyNowPrice={buyNowPrice}
                    margin="5px"
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}

      {!isEmpty(questions) && (
        <Box margin={1}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Question(s)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                display="flex"
                flexDirection="column"
                alignContent="stretch"
                width="100%"
              >
                {questions.map(({ id, text, answer, createdAt }) => (
                  <Box key={id}>
                    <Box>{text}</Box>
                    <Box style={{ textAlign: 'right' }}>
                      Asked on
                      <FormatDateTZ
                        paddingLeft={1}
                        date={createdAt}
                        format="MMM D @ h:mm a"
                      />
                    </Box>
                    {dealer || answer?.text ? (
                      <>
                        <Box>
                          <b>Answer: </b>
                          {answer?.text || 'N/A'}
                        </Box>
                        {answer && (
                          <Box textAlign="right">
                            Answered on
                            <FormatDateTZ
                              date={answer.createdAt}
                              format="MMM D @ h:mm a"
                              paddingLeft={1}
                            />
                          </Box>
                        )}
                      </>
                    ) : (
                      !pendingOffer.acceptedAt &&
                      !pendingOffer.declinedAt && (
                        <>
                          <Box>
                            <TextField
                              style={{ width: '100%' }}
                              label="Answer"
                              value={answer}
                              onChange={e => setAnswer(e.target.value)}
                            />
                          </Box>
                          <Box textAlign="right" paddingTop="1rem">
                            <Button
                              variant="contained"
                              style={theme.actions.confirm}
                              onClick={sendAnswerHandler(id)}
                            >
                              <DoneIcon />
                              Send
                            </Button>
                          </Box>
                        </>
                      )
                    )}
                  </Box>
                ))}

                {!isEmpty(questions) &&
                  dealer &&
                  !pendingOffer.acceptedAt &&
                  !pendingOffer.declinedAt && (
                    <>
                      <Box>
                        <Typography variant="h6">
                          What questions do you have?
                        </Typography>
                      </Box>
                      <Box>
                        <TextField
                          style={{ width: '100%' }}
                          label="Question"
                          value={question}
                          onChange={e => setQuestion(e.target.value)}
                        />
                      </Box>
                      <Box
                        item
                        style={{
                          paddingTop: '1rem',
                          display: 'flex',
                          flexDirection: 'row',
                          marginLeft: 'auto',
                          marginRight: 0,
                        }}
                      >
                        <Button
                          variant="contained"
                          style={theme.actions.confirm}
                          onClick={handleAskQuestion}
                        >
                          <DoneIcon />
                          Send
                        </Button>
                      </Box>
                    </>
                  )}
                {isQuestionsPending && dealer && (
                  <Box textAlign="right" paddingTop={1} paddingBottom={1}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: '#009dff',
                        color: 'white',
                      }}
                      onClick={() =>
                        resolveQuestions({
                          variables: { id: pendingOffer.id },
                        }).then(refetch)
                      }
                    >
                      <DoneIcon />
                      Questions Answered!
                    </Button>
                  </Box>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{'Vehicle Photos'}</DialogTitle>
        <DialogContent>
          <ImageCarousel photos={photos} currentIndex={currentIndex} />
        </DialogContent>
      </Dialog>
    </>
  );
};

// bill_of_sale_url was in the fragment below.  Not sure why....Maybe it's in a
// sub component?  If so, it should be on _that_ component.
// Looks like photos aren't used here either....
AppraisalDetails.fragments = {
  appraisal: gql`
    fragment AppraisalDetailsAppraisal on Appraisal {
      appraisalStatus
      bodyType
      buyNowPrice
      contactEmail
      contactName
      contactPhone
      createdAt
      customerMotive
      customerPreferredContactMethod
      deliveredAt
      exteriorColour
      hasLien
      hasOpi
      interiorColour
      isDelivered
      isGlassGood
      isPaveSessionComplete
      isQuestionsPending
      isTiresGood
      make
      model
      notes
      odometer
      paveSessionId
      offers {
        id
        acceptedAt
        dealerId
        dealer {
          name
        }
        declinedAt
        offerStatus
        rescindedAt
        questions {
          id
          text
          createdAt
          answer {
            id
            text
            createdAt
          }
        }
      }
      photos {
        id
        cloudinaryPublicId
        photoType
      }
      buyernotes {
        id
        createdAt
        createdByUser {
          display_name
        }
        dealer {
          name
        }
        note
      }
      price
      soldAt
      trim
      trimVariation
      vin
      year
    }
  `,
};
export default AppraisalDetails;
