import gql from 'graphql-tag';
import moment from 'moment';
import React from 'react';

/* external */
import { useHistory } from 'react-router-dom';

import { Box, Card, CardContent, Grid } from '@mui/material';

/* Material UI */
import CardActionArea from '@mui/material/CardActionArea';
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';
import Icon from '@mui/material/Icon';
import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useUserContext } from 'components/MaterialUI/UserContext';

/* internal */
import { AppraisalStatus, OfferStatus } from 'modules/used_vehicles/const';
import { paveCaptureLink, paveReportLink } from 'modules/appraisals/utils';

import { formatPrice, numberWithCommas, defaultTZ } from 'utils';

import DashboardCardStatus from './DashboardCardStatus';

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: '14px',
    marginBottom: '0.75em',
  },
  subHeader: {
    fontSize: '12px',
  },
  content: {
    fontSize: '12px',
  },
  grey: {
    color: '#999999',
  },
  root: {
    marginBottom: '1em',
  },
}));

const DashboardCard = ({
  appraisal,
  offer,
  users,
  usersLoading,
  canClaimOrAssignOffer,
  dealer = false,
}) => {
  const classes = useStyles();
  const { currentUser } = useUserContext();
  const history = useHistory();

  const timezone = currentUser?.goUserProfile?.settings?.timezone || defaultTZ;
  const shortDate = x => moment(x).tz(timezone).format('ll');

  const {
    appraisalStatus,
    arrivedAt,
    auctionAttempts,
    auctionItem,
    buyer,
    createdAt,
    createdByUser,
    customerAppraisalSource,
    declineReason,
    deliveredAt,
    exteriorColour,
    interiorColour,
    isBuyNow,
    isPaveSessionComplete,
    locatedProvince,
    make,
    model,
    odometer,
    offers,
    openOffer,
    paveSessionId,
    price,
    purchasingDealer,
    trim,
    trimVariation,
    year,
  } = appraisal || {};

  const displayName = [year, make, model, trim].filter(x => x).join(' ');

  const offerPendingQuestions = offers?.some(offer => offer.isQuestionsPending);

  const allDeclined = offers.every(
    offer => offer.offerStatus === OfferStatus.DECLINED,
  );

  const declined =
    dealer && offer && offer.offerStatus === OfferStatus.DECLINED;

  const buyNowOffers = offers.filter(
    ({ offerStatus }) => offerStatus === OfferStatus.BUY_NOW_REQUEST,
  );

  const isSold =
    appraisalStatus === AppraisalStatus.SOLD ||
    appraisalStatus === AppraisalStatus.AUCTIONED;

  return (
    <Card
      style={{ width: '100%', position: 'relative' }}
      className={classes.root}
    >
      <CardActionArea
        onClick={
          offer
            ? canClaimOrAssignOffer
              ? () => history.push(`/used-vehicles/offers/${offer.id}`)
              : null
            : () => history.push(`/used-vehicles/appraisals/${appraisal.id}`)
        }
      >
        {declined && (
          <svg
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          >
            <line
              x1="0"
              y1="0"
              x2="100%"
              y2="100%"
              style={{ stroke: 'rgb(255, 0, 0)', strokeWidth: 2 }}
            />
          </svg>
        )}
        <CardContent
          style={{
            padding: '1rem',
            backgroundColor: declined ? 'lightgrey' : '',
          }}
        >
          <DashboardCardStatus
            appraisal={appraisal}
            dealer={dealer}
            offer={offer}
            users={users}
            usersLoading={usersLoading}
            canClaimOrAssignOffer={canClaimOrAssignOffer}
            showChip
            showIcon
          />
          {appraisalStatus === AppraisalStatus.DECLINED && (
            <Box>
              <b>Declined:</b> {declineReason}
            </Box>
          )}

          {!dealer && isBuyNow && (
            <Box pb="0.25rem">BUY NOW ({buyNowOffers.length} requests)</Box>
          )}

          {!dealer &&
            appraisalStatus === AppraisalStatus.OWNED &&
            auctionAttempts > 0 && (
              <Box pb="0.25rem">
                {auctionAttempts} Auction Attempt
                {auctionAttempts > 1 ? 's' : null}
              </Box>
            )}

          {!dealer && offers.length > 0 && allDeclined && (
            <Box paddingBottom="0.25rem">
              All {offers.length} offers have been declined
            </Box>
          )}
          {!dealer && appraisalStatus === AppraisalStatus.PENDING_OFFER && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              paddingBottom="0.25rem"
            >
              {openOffer && (
                <Box>
                  <b>Offered to: </b>
                  {openOffer.dealer.name}
                  <b>
                    ({openOffer.order + 1}/{offers?.length}){' '}
                  </b>
                </Box>
              )}
              {offerPendingQuestions && (
                <Box fontSize="20px" color="blue">
                  <i className="fas fa-question-circle" />
                </Box>
              )}
            </Box>
          )}

          {!dealer &&
            [AppraisalStatus.SOLD, AppraisalStatus.AUCTIONED].includes(
              appraisalStatus,
            ) && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingBottom="0.25rem"
              >
                <Box>
                  <b>Sold to:</b>{' '}
                  {purchasingDealer?.name ||
                    auctionItem?.leadingBid?.dealer?.name}
                </Box>
              </Box>
            )}

          <Box
            className={classes.header}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box paddingRight="1rem">
              <Box
                component="span"
                style={{
                  paddingRight: '0.25rem',
                  display: 'inline-block',
                }}
              >
                <b>{displayName}</b>
              </Box>
              <Box
                component="span"
                className={`${classes.grey} ${classes.subHeader}`}
                style={{ display: 'inline-block' }}
              >
                {trimVariation}
              </Box>
            </Box>
            {dealer && <Box>{formatPrice(price)}</Box>}
          </Box>

          <Grid className={classes.content} container spacing={2}>
            <Grid container item xs={6}>
              <Grid item xs={6}>
                <b>Int:</b> {interiorColour}
              </Grid>
              <Grid item className={classes.grey} xs={6}>
                <b>Ext:</b> {exteriorColour}
              </Grid>
            </Grid>
            {isSold && (
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <b>Delivered:</b>{' '}
                {`${deliveredAt ? shortDate(deliveredAt + 'Z') : 'N/A'}`}
              </Grid>
            )}
          </Grid>
          {!dealer && arrivedAt && (
            <Grid container>
              <Grid
                item
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 'auto',
                  marginRight: 0,
                }}
              >
                <Box>
                  <b>Arrival Date: </b>
                  <FormatDateTZ date={arrivedAt} format="ll" />
                </Box>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Grid item>
              <b>Odometer:</b> {numberWithCommas(odometer)}km
            </Grid>
            <Grid item>
              <b>Created:</b> {shortDate(createdAt + 'Z')}
            </Grid>
            <Grid item xs={12}>
              <b>Located Province:</b> {locatedProvince ?? ''}
            </Grid>
          </Grid>
          {!dealer && (
            <Grid
              container
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Grid item>
                <b>Buyer:</b> {buyer || 'N/A'}
              </Grid>
              <Grid item>
                <b>Creator:</b> {createdByUser?.display_name}
              </Grid>
            </Grid>
          )}
          {customerAppraisalSource && (
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Box>
                <b>Source:</b> {customerAppraisalSource}
              </Box>
            </Box>
          )}
          <Grid item xs={12}>
            {!dealer &&
              paveSessionId &&
              !isPaveSessionComplete &&
              !customerAppraisalSource && (
                <Link
                  style={{ textUnderlineOffset: '0.3em' }}
                  href={paveCaptureLink(paveSessionId)}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <Box display="flex" alignItems="center">
                    Open Pave Session{' '}
                    <Icon
                      className="fas fa-road"
                      style={{ width: 'auto', marginLeft: '5px' }}
                    />
                  </Box>
                </Link>
              )}
            {isPaveSessionComplete && paveSessionId && (
              <Link
                style={{ textUnderlineOffset: '0.3em' }}
                href={paveReportLink(paveSessionId)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <Box display="flex" alignItems="center">
                  View PAVE Report{' '}
                  <Icon
                    className="fas fa-road"
                    style={{ width: 'auto', marginLeft: '5px' }}
                  />
                </Box>
              </Link>
            )}
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

// bought_now_at doesn't seem to be used here.  sub-component?
// is_deleted ?
// rescinded_at ?
// sold_at ?
DashboardCard.fragments = {
  appraisal: gql`
    fragment DashboardCardAppraisal on Appraisal {
      id
      appraisalStatus
      arrivedAt
      auctionAttempts
      auctionItem {
        leadingBid {
          id
          dealer {
            id
            name
          }
        }
      }
      boughtNowAt
      buyer
      createdBy
      createdByUser {
        display_name
      }
      createdAt
      customerAppraisalSource
      declineReason
      deliveredAt
      exteriorColour
      interiorColour
      isBuyNow
      isDeleted
      isPaveSessionComplete
      isQuestionsPending
      locatedProvince
      make
      model
      modifiedAt
      odometer
      offers {
        id
        dealer {
          name
        }
        isQuestionsPending
        offerStatus
        order
        rescindedAt
      }
      openOffer {
        id
        dealer {
          name
        }
        isQuestionsPending
        offerStatus
        order
        rescindedAt
      }
      paveSessionId
      price
      purchasingDealer {
        name
      }
      seller {
        name
      }
      soldAt
      trim
      trimVariation
      year
      ...DashboardCardStatusAppraisal
    }
    ${DashboardCardStatus.fragments.appraisal}
  `,
  offer: gql`
    fragment DashboardCardOffer on Offer {
      id
      ...DashboardCardStatusOffer
    }
    ${DashboardCardStatus.fragments.offer}
  `,
};

export default DashboardCard;
