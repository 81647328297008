import React from 'react';

/* external */
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import { useTheme } from '@mui/material';

/* internal */
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { Status } from 'constants.js';

const SET_PENDING = gql`
  mutation setPending(
    $opportunityId: ID!
    $input: UpdateOpportunityStatusInput!
  ) {
    updateOpportunityStatus(_id: $opportunityId, input: $input) {
      _id
      status
      last_status_change
      status_display
    }
  }
`;
const PendingOpportunityModal = ({ opportunityId, onClose, ...rest }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [setPending, { loading }] = useMutation(SET_PENDING);

  const onConfirm = () => {
    onClose();
    setPending({
      variables: { opportunityId, input: { status: Status.PENDING } },
    }).then(
      () =>
        enqueueSnackbar('Opportunity sent to deal log!', {
          variant: 'success',
        }),
      ({ graphQLErrors }) =>
        enqueueSnackbar(
          `Opportunity could not be sent to deal log: ${
            graphQLErrors?.[0]?.message ?? 'Unknown error'
          }`,
          { variant: 'error' },
        ),
    );
  };
  return (
    <>
      <LoadingBackdrop open={loading}>Sending to deal log...</LoadingBackdrop>
      <ConfirmDialog
        abortButtonProps={{ style: theme.actions.cancel }}
        confirmButtonProps={{ style: theme.actions.confirm }}
        onConfirm={onConfirm}
        onClose={onClose}
        text=""
        abortText="Cancel"
        confirmText="Send"
        titleText="Send opportunity to deal log?"
        {...rest}
      ></ConfirmDialog>
    </>
  );
};

export default PendingOpportunityModal;
