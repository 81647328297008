/* External */
import React from 'react';
import gql from 'graphql-tag';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

/* Internal */
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import { snackBarSettings } from 'constants.js';
import Loading from 'components/MaterialUI/Loading';
import { defaultTZ } from 'utils';

export const UPDATE_SETTINGS = gql`
  mutation updateSettings($gatewayId: Int!, $settings: GatewaySettingsInput!) {
    sms {
      updateGatewaySettings(gatewayId: $gatewayId, settings: $settings) {
        id
        gateway_type
        phone_number
        delivery_endpoint
        name
        black_out_start
        black_out_end
        username
        password
        secret_key
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  h6: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  titleLine: {
    borderBottom: '1px solid' + theme.colours.greys.grey3,
    marginBottom: '15px',
  },
  textField: {
    width: '90%',
    margin: '5px 0',
  },
  settingsContainer: {
    width: '80%',
    paddingBottom: '20px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  blackoutLabel: {
    fontWeight: 600,
    margin: '5px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  saveButton: {
    width: '100%',
    backgroundColor: theme.actions.confirm.backgroundColor,
    color: theme.actions.confirm.color,
  },
}));

const GeneralSettings = ({ gateway }) => {
  const classes = useStyles();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { enqueueSnackbar: snackIt } = useSnackbar();

  // makes it less of a PITA to grab the data in the fields below
  const gatewayData = gateway?.sms.gateway;
  const [updateSettings, { loading }] = useMutation(UPDATE_SETTINGS, {
    onCompleted: () => {
      snackIt('Updated settings successfully!', {
        ...snackBarSettings,
        variant: 'success',
      });
    },
    onError: error => {
      snackIt(error.message, {
        ...snackBarSettings,
        variant: 'error',
      });
    },
  });

  function utcToLocal(utcStr) {
    if (!utcStr) return '00:00:00';
    const [h, m, s] = utcStr.split(':');
    const nowish = new Date();
    nowish.setUTCHours(h, m, s);
    return new Intl.DateTimeFormat(undefined, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: defaultTZ,
    }).format(nowish);
  }

  function localToUtc(localStr) {
    if (!localStr) return '00:00:00';
    const [h, m, s] = localStr.split(':');
    const nowish = new Date();
    nowish.setHours(h, m, s);
    return new Intl.DateTimeFormat(undefined, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: 'UTC',
    }).format(nowish);
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues: {
      gateway_type: gatewayData.gateway_type,
      phone_number: gatewayData.phone_number,
      delivery_endpoint: gatewayData.delivery_endpoint,
      name: gatewayData.name,
      // Username or SID
      username: gatewayData.username,
      password: gatewayData.password,
      black_out_start: utcToLocal(gatewayData.black_out_start),
      black_out_end: utcToLocal(gatewayData.black_out_end),
    },
  });

  const onSubmit = settings => {
    settings.black_out_start = localToUtc(settings.black_out_start);
    settings.black_out_end = localToUtc(settings.black_out_end);
    updateSettings({
      variables: {
        gatewayId: gatewayData.id,
        settings: settings,
      },
    });
  };

  const handleGenerateClick = () => {
    updateSettings({
      variables: {
        gatewayId: gatewayData.id,
        settings: { regenerate_secret_key: true },
      },
    });
  };

  if (loading) return <Loading />;

  return (
    <Box>
      <Box className={classes.titleLine}>
        <Typography className={classes.h6}>General Settings</Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className={classes.settingsContainer}>
          <Grid item xs={12} sm={6}>
            {/* will these values ever change? */}
            <TextFieldControl
              control={control}
              disabled
              name="gateway_type"
              label="Type"
              className={classes.textField}
              muiVariant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* will these values ever change? */}
            <TextFieldControl
              control={control}
              disabled
              name="phone_number"
              label="Phone"
              className={classes.textField}
              muiVariant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldControl
              control={control}
              name="delivery_endpoint"
              label="Delivery Endpoint"
              className={classes.textField}
              muiVariant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldControl
              control={control}
              name="name"
              label="Gateway Name"
              className={classes.textField}
              muiVariant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldControl
              control={control}
              name="username"
              label="Username or SID"
              className={classes.textField}
              muiVariant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldControl
              control={control}
              name="password"
              label="Password or Token"
              className={classes.textField}
              muiVariant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              defaultValue={gatewayData.secret_key}
              disabled
              label="Secret Key"
              className={classes.textField}
              muiVariant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} />
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerateClick}
            >
              Generate a new key
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} />
          <Grid container item xs={12} lg={6}>
            <Grid item xs={12}>
              <Typography className={classes.blackoutLabel}>
                Do not send subscriptions between these hours:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="black_out_start"
                style={{ width: '100%' }}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    id="blackout-start-time"
                    label="Start"
                    type="time"
                    className={classes.textField}
                    variant="outlined"
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="black_out_end"
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    id="blackout-end-time"
                    label="End"
                    type="time"
                    className={classes.textField}
                    variant="outlined"
                    {...rest}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent={mobile ? 'center' : 'flex-end'}
            alignItems="center"
            spacing={mobile ? 2 : 4}
            wrap="wrap"
          >
            <Grid item xs={12} sm={5} md={4} xl={2}>
              <Button
                variant="outlined"
                style={{ width: '100%' }}
                onClick={() => reset()}
              >
                Discard Changes
              </Button>
            </Grid>
            <Grid item xs={12} sm={5} md={4} xl={2}>
              <Button
                variant="contained"
                type="submit"
                disabled={!isDirty}
                className={classes.saveButton}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

GeneralSettings.fragments = {
  gateway: gql`
    fragment GeneralSettingsGatewayData on Gateway {
      gateway_type
      phone_number
      delivery_endpoint
      name
      black_out_start
      black_out_end
      username
      password
      enable_automatic_lead_generation
      lead_dealer_id
      send_lead_after_number_of_hours
      secret_key
    }
  `,
};

export default GeneralSettings;
