import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { URL } from 'constants.js';

/* Material UI */
import {
  TextField,
  Grid,
  Box,
  Button,
  useTheme,
  Typography,
} from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';

/* internal */
import Loading from 'components/MaterialUI/Loading';

const GET_BROCHURE_POST_URL = gql`
  mutation getBrochurePostUrl($id: Int!) {
    npv {
      createBrochurePresignedPost(id: $id) {
        url
        fields {
          AWSAccessKeyId
          key
          policy
          signature
        }
      }
    }
  }
`;

const ADD_BROCHURE = gql`
  mutation uploadBrochure($id: Int!, $input: BrochureUpload!) {
    npv {
      attachBrochure(id: $id, input: $input) {
        id
        name
        brochureUrl
      }
    }
  }
`;

const BrochureUpload = ({ makeId }) => {
  const theme = useTheme();
  const [file, setFile] = useState(null);
  const [newLabel, setNewLabel] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [uploading, setUploading] = useState(false);

  const [addBrochure] = useMutation(ADD_BROCHURE, {
    onCompleted: () => {
      enqueueSnackbar('Brochure Added!', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not add Brochure error: ${JSON.stringify(err)}`, {
        variant: 'error',
      });
    },
  });

  const [GetBrochurePostUrl] = useMutation(GET_BROCHURE_POST_URL, {
    onCompleted: async data => {
      const { fields, url } = data.npv.createBrochurePresignedPost;
      const { __typename, ...uploadFields } = fields;
      const formData = new FormData();
      let s3key = '';
      Object.entries(uploadFields).forEach(([key, value]) => {
        if (key === 'key') {
          s3key = value;
        }
        formData.append(key, value);
      });

      formData.append('file', file, newLabel);

      try {
        setUploading(true);
        await fetch(url, {
          method: 'POST',
          mode: 'no-cors',
          body: formData,
        });
        addBrochure({
          variables: {
            id: makeId,
            input: {
              brochureUrl: `${URL.npvBrochure}${s3key}`,
            },
          },
        });
      } catch (err) {
        enqueueSnackbar(
          `Could not save Brochure error: ${JSON.stringify(err)}`,
          {
            variant: 'error',
          },
        );
      } finally {
        setUploading(false);
      }
    },
  });

  async function handleLabelChange(event) {
    const files = event.target.files;
    setFile(files[0]);
    setNewLabel(files[0].name);
  }

  const handleUpload = () => {
    GetBrochurePostUrl({
      variables: {
        id: makeId,
      },
    });
  };

  if (uploading) return <Loading text="Uploading..." />;
  return (
    <Box style={{ marginBottom: '0.5rem' }}>
      <Typography>Upload factory brochure</Typography>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            size="small"
            style={{
              width: '100%',
            }}
            disabled
            value={newLabel}
          />
        </Grid>
        <Grid item xs={6}>
          <span
            style={{
              paddingLeft: '1rem',
            }}
          >
            <input
              style={{ display: 'none' }}
              id="attachment-file"
              type="file"
              onChange={handleLabelChange}
            />
          </span>
          <label htmlFor="attachment-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              style={{ marginRight: '1rem' }}
            >
              <CloudUploadIcon
                fontSize="small"
                style={{ marginRight: '0.5rem' }}
              />
              Browse
            </Button>
          </label>
          <Button
            onClick={handleUpload}
            color="primary"
            variant="contained"
            disabled={!file}
            style={!file ? theme.actions.disabled : theme.actions.confirm}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BrochureUpload;
