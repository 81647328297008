import React, { useEffect, useState } from 'react';
import cloudinary from 'cloudinary-core';
import CardMedia from '@mui/material/CardMedia';
import { cloudinaryConfig } from '../../../../constants';
import {
  cloudinaryTranscriptParams,
  cloudinaryVideoParams,
  configureCloudinaryVideoParams,
} from '../../const';

function VideoPlayer({ publicId }) {
  const cloudinaryCore = new cloudinary.Cloudinary({
    cloud_name: cloudinaryConfig.cloud_name,
  });

  const [videoUrl, setVideoUrl] = useState(
    cloudinaryCore.url(publicId, cloudinaryVideoParams),
  );
  const [didCheckTranscript, setDidCheckTranscript] = useState(false);

  useEffect(() => {
    async function checkTranscript() {
      const exists = await checkIfTranscriptExists(cloudinaryCore, publicId);
      if (exists) {
        setVideoUrl(
          cloudinaryCore.url(
            publicId,
            configureCloudinaryVideoParams(publicId),
          ),
        );
      }
      setDidCheckTranscript(true);
    }
    if (!didCheckTranscript) {
      checkTranscript();
    }
  }, [publicId]);

  return (
    <CardMedia
      src={videoUrl}
      component="video"
      height={180}
      controls
      bgcolor="text.secondary"
    />
  );
}

async function checkIfTranscriptExists(cloudinaryCore, publicId) {
  try {
    const transcriptUrl = cloudinaryCore.url(
      publicId,
      cloudinaryTranscriptParams,
    );
    const response = await fetch(transcriptUrl);
    return response.ok; // Returns true if the transcript exists
  } catch (error) {
    console.error('Error checking transcript:', error);
    return false;
  }
}

export default VideoPlayer;
