// External
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';

// Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import makeStyles from '@mui/styles/makeStyles';

import TextFieldControl from 'components/MaterialUI/TextFieldControl';

// Internal

const UPDATE_CONVERSATION = gql`
  mutation updateConversation(
    $gatewayId: Int!
    $conversationId: Int!
    $data: UpdateConversationInput!
  ) {
    sms {
      updateConversation(
        gatewayId: $gatewayId
        conversationId: $conversationId
        data: $data
      ) {
        id
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px 25px',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}));

const EditClientDetailsDialog = ({
  refetch,
  conversation,
  gatewayId,
  onClose,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      customerName: conversation.customer_name,
      customerEmail: conversation.customer_email,
      stopBulkMessages: conversation.skip_bulk_emails_until,
      doNotContact: conversation.do_not_contact,
    },
  });

  const classes = useStyles();

  const [updateConversation] = useMutation(UPDATE_CONVERSATION, {
    onCompleted: () => {
      onClose();
      refetch();
      enqueueSnackbar('Changes saved!', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Unable to save changes: ${err}`, {
        variant: 'error',
      });
    },
  });

  const onSubmit = ({
    customerName,
    customerEmail,
    stopBulkMessages,
    doNotContact,
  }) => {
    updateConversation({
      variables: {
        gatewayId,
        conversationId: parseInt(conversation.id, 10),
        data: {
          customer_name: customerName,
          skip_bulk_emails_until: stopBulkMessages,
          do_not_contact: doNotContact,
          customer_email: customerEmail,
        },
      },
    });
  };
  return (
    <Dialog maxWidth="sm" fullWidth onClose={onClose} {...rest}>
      <DialogTitle>Edit Client Details</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '0 10px' }}>
        <DialogContent className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextFieldControl
                control={control}
                name="customerName"
                label="Customer Name"
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldControl
                control={control}
                name="customerEmail"
                label="Customer Email"
                style={{ width: '100%' }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="stopBulkMessages"
                render={({ field: { ref, value, ...rest } }) => (
                  <DatePicker
                    id="date-picker-bulk-messages"
                    format="YYYY-MM-DD"
                    label="Stop Bulk Messages Until"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    value={moment(value)}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Controller
                      name="doNotContact"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          onChange={e => field.onChange(e.target.checked)}
                          checked={field.value}
                        />
                      )}
                    />
                  }
                  label="Do Not Contact"
                  labelPlacement="top"
                  style={{ alignItems: 'flex-start' }}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <Button type="submit" disabled={!isDirty}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditClientDetailsDialog;
