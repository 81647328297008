export const cloudinaryVideoParams = {
  width: 640,
  height: 480,
  resource_type: 'video',
  crop: 'fit',
  format: 'mp4',
};

export const cloudinaryTranscriptParams = {
  resource_type: 'raw',
  format: 'transcript',
};

export function configureCloudinaryVideoParams(publicId) {
  return {
    width: 640,
    height: 480,
    resource_type: 'video',
    crop: 'fit',
    format: 'mp4',
    transformation: [
      {
        overlay: {
          resource_type: 'subtitles',
          public_id: `${publicId}.transcript`,
        },
      },
      { flags: 'layer_apply' },
    ],
  };
}

export const defaultFacet = [
  {
    model: 'ServiceRO',
    field: 'is_open',
    value: 'True',
    options: { label: 'Open ROs' },
  },
];
