// External
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';

// Material UI
import { Box, Button, IconButton, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Send } from '@mui/icons-material';
import PublishIcon from '@mui/icons-material/Publish';
import makeStyles from '@mui/styles/makeStyles';

// Internal
import { parseErrors } from 'modules/sms/utils';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const SEND_MESSAGE = gql`
  mutation sendMessage($gatewayId: Int!, $message: SMSMessageInput!) {
    sms {
      sendMessage(gatewayId: $gatewayId, message: $message) {
        id
      }
    }
  }
`;

const GET_FILE_URLS = gql`
  query get_file_urls($file_names: [String!]!) {
    sms {
      fileURLs(file_names: $file_names) {
        download
        upload {
          url
          fields
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
    },
    marginRight: '5px',
  },
}));

const ConversationFooter = ({
  gatewayId,
  customerPhone,
  doNotContact,
  refetch,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [messageText, setMessageText] = useState(() => {
    return (
      sessionStorage.getItem(
        'sms.components.Messages.ConversationFooter.messageText',
      ) || ''
    );
  });
  const handleChange = e => setMessageText(e);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const classes = useStyles();

  // messageText gets lost if the conversation reloads,
  // persist the data by grabbing it from the session storage.
  // data will be lost/reset/cleared if the tab/window is closed
  // or user navigates away from the conversation
  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem(
        'sms.components.Messages.ConversationFooter.messageText',
        messageText,
      );
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      sessionStorage.removeItem(
        'sms.components.Messages.ConversationFooter.messageText',
      );
    };
  }, [messageText]);

  const [sendMessage, { loading }] = useMutation(SEND_MESSAGE, {
    onCompleted: () => refetch(),
    onError: err =>
      enqueueSnackbar(`Error sending message: ${parseErrors(err)}`, {
        variant: 'error',
      }),
  });

  const [getImageURLs] = useLazyQuery(GET_FILE_URLS, {
    onCompleted: data => {
      Promise.all(
        data.sms.fileURLs.map((file, i) => {
          const {
            upload: { url, fields },
          } = file;
          const formData = new FormData();
          Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append('file', selectedFiles[i], selectedFiles[i].name);
          return fetch(url, {
            method: 'POST',
            body: formData,
          });
        }),
      )
        .then(response => {
          let message = data.sms.fileURLs.map(file => file.download).join('\n');
          sendMessage({
            variables: {
              gatewayId: gatewayId,
              message: { sent_to: customerPhone, body: message },
            },
          }).then(() => {
            enqueueSnackbar('File(s) sent successfully', {
              variant: 'success',
            });
          });
        })
        .catch(() => {
          enqueueSnackbar('Error sending files', { variant: 'error' });
        });
    },
  });

  const handleSend = () => {
    sendMessage({
      variables: {
        gatewayId,
        message: { sent_to: customerPhone, body: messageText },
      },
    });
    setMessageText('');
  };

  const handleKeyUp = e => {
    if (e.key === 'Enter' && e.ctrlKey) handleSend();
  };

  const handleSendFiles = e => {
    const files = e.target.files;
    setSelectedFiles(Array.from(files));
    const file_names = Array.from(files).map(file => file.name);

    getImageURLs({
      variables: {
        file_names,
      },
    });
  };

  return (
    <>
      <LoadingBackdrop open={loading}>Sending...</LoadingBackdrop>
      <Box display="flex" alignItems="flex-start" width="100%">
        <TextField
          variant="outlined"
          disabled={doNotContact}
          label={doNotContact ? 'DO NOT CONTACT' : 'Type Message...'}
          style={{ width: '85%' }}
          onChange={e => handleChange(e.target.value)}
          multiline
          maxRows={14}
          className={classes.root}
          value={messageText}
          onKeyUp={handleKeyUp}
          helperText={desktop ? 'Press Ctrl+Enter to send.' : null}
          inputProps={{ maxLength: 2000 }}
        />
        <IconButton
          color="secondary"
          component="label"
          disabled={doNotContact}
          size="large"
        >
          <PublishIcon fontSize="large" />
          <input
            type="file"
            style={{ display: 'none' }}
            id="sms-file"
            multiple
            onChange={handleSendFiles}
          ></input>
        </IconButton>

        {desktop && (
          <Button
            color="secondary"
            variant="contained"
            startIcon={<Send />}
            disabled={!messageText}
            size="large"
            style={{
              fontWeight: 'bold',
              padding: '12px 30px',
              fontSize: '1.125rem',
            }}
            onClick={() => handleSend()}
          >
            Send
          </Button>
        )}
        {!desktop && (
          <IconButton
            disabled={!messageText}
            onClick={handleSend}
            color="secondary"
            size="large"
          >
            <Send fontSize="large" />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default ConversationFooter;
